<template>
  <div>
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
            <a-form-item>
                <a-button @click='toEdit()' type="primary" icon="plus" >新增规则</a-button>
            </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
              <a-tooltip placement="topLeft" >
              <template slot="title">
                  <span>表单名称</span>
              </template>
              <a-input v-model.trim="searchParams.search.sop_name" placeholder="请输入表单名称" style="width: 160px"/>
              </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table :pagination="false" :bordered='false' rowKey="sop_id" size="small"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="sop_works" slot-scope="text, record , index">
            <span v-if="record.sop_works.length === 0">--</span>
            <a-dropdown v-else>
                <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                  {{record.sop_works[0].employee_name}}等{{record.sop_works.length}}个人<a-icon type="caret-down" />
                </div>
                <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                    <a-menu-item style="text-align: center" v-for="(it,j) in record.sop_works" :key="j">
                        {{it.employee_name}}
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </template>
        <template slot="action" slot-scope="text,record">
          <a v-if="canUpdate" href="javascript:;" @click="toEdit(record.sop_id)">编辑</a>
          <a-divider v-if="canUpdate" type="vertical" />
          <a v-if="canDelete" href="javascript:;" @click="deleteItem(record)">删除</a>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: 'SOP名称', dataIndex: 'sop_name', key: '9'},
  { title: 'SOP类型', dataIndex: 'sop_type_name', key: 'sop_type_name'},
  { title: '使用成员', dataIndex: 'sop_works', key: 'sop_works',scopedSlots: { customRender: 'sop_works' }},
  { title: '创建人', dataIndex: 'created_by', key: 'created_by',},
  { title: '创建时间', dataIndex: 'created_at', key: 'created_at'},
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' },width:'100px'}
]

    import tableMixins from '@/common/mixins/table'
    import authority from '@/common/mixins/authority'
    export default {
        name:"list",
        inject: ['parent'],
        data() {
            return {
                columns,
                loading:false,
                list: [],
                modalData:{},
                isEditModal:false,
                authType:['crm','discourse'],
            }
        },
        props:{
            groupID:Number
        },
        mixins: [ tableMixins, authority ],
        methods: {
            async getList() {
                if(this.groupID === 0) return false
                this.loading = true
                this.searchParams.search.sop_group = this.groupID
                let res = await this.$store.dispatch('sopIndexAction', this.searchParams)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            toEdit(id){
              this.$router.push({path:'/market/sop/edit',query:{group_id:this.groupID,sop_id:id}})

            },
            deleteItem(item){
              let that = this
              this.$confirm({
                  title: `您确定删除该sop？`,
                  okText: '确定',
                  okType: 'danger',
                  cancelText: '取消',
                  onOk() {
                  return new Promise(async (resolve, reject) => {
                      await that.$store.dispatch('sopDeleteAction', {sop_id:item.sop_id})
                      .then(res=>{
                          that.$message.success('操作成功~')
                          that.getList()
                          that.parent.refreshList()
                          resolve(res)
                      })
                      .catch(err=>{
                          that.$message.error('操作失败~')
                          reject(err)
                      })
                  }).catch(error => console.log(error))
                  }
              })
            }
        },
        watch: {
            groupID(newValue, oldValue) {
                this.searchParams.page = 1
                this.getList()
            }
        },
    }
</script>

<style lang="scss" scoped>
  .talkingSkills-list-content{
    &-img{
      display: flex;
      align-items: center;
      min-height: 60px;
      img{
        max-height: 60px;
        max-width: 60px;
      }
      div{
        margin-left: 10px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
    &-video{
      display: flex;
      align-items: center;
      max-height: 60px;
      min-height: 30px;
      img{
        max-height: 60px;
        max-width: 60px;
      }
      div{
        margin-left: 10px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
      a{
        position: relative;
        .talkingSkills-list-content-video-icon{
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -6px;
          margin-left: -6px;
        }

      }
    }
  }
</style>