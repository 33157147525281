<template>
    <div class="talkingSkills-left">
        <div v-if="canSave" @click="showEditModal()" class="talkingSkills-left-nav">
            <a-icon type="plus" />
            <span style="margin-left:10px">添加分组</span>
        </div>
        <div 
            class="talkingSkills-left-list"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
        >
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <draggable v-model="list" @end="onEnd">
                <transition-group>
                    <div 
                        v-for="(item,index) in list" 
                        :key="item.group_id" 
                        @click="toCheck(item,index)" 
                        @mouseover="hadleOver(index)" 
                        @mouseout="handleOut" 
                        class="talkingSkills-left-item" 
                        :class="{'talkingSkills-left-list-bg':hoverIndex == index,'talkingSkills-left-list-bgF':checkIndex == index}"
                    >
                        <span>{{item.group_name}}</span>
                        <a-dropdown v-if="item.group_can_edit" placement="bottomRight" class='talkingSkills-left-item-icon'>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-icon type="more" />
                            </a>
                            <a-menu slot="overlay">
                            <a-menu-item v-if="canUpdate" @click="showEditModal(item)">
                                编辑
                            </a-menu-item>
                            <a-menu-item v-if="canDelete" @click="toDel(item)">
                                删除
                            </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <span class="talkingSkills-left-item-count">( {{item.group_sop_count}} )</span>
                    </div>
                </transition-group>
            </draggable>
        </div>
        <editModal v-if="isEditModal" :item='modalData' />
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import editModal from './editModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import authority from '@/common/mixins/authority'
    export default {
        name:'grouping',
        inject: ['parent'],
        data() {
            return {
                myHeight:0,
                busy:true,
                loading:false,
                isEditModal:false,
                list:[],
                modalData:{},
                hoverIndex:-1,
                checkIndex:0,
                authType:['crm','discourse'],
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
            draggable
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 240}px`
            }
        },
        directives: { infiniteScroll },
        mixins:[ authority ],
        created () {
            this.myHeight = this.windowHeight()
            this.getList()
        },
        methods: {
            async getList(val){
                this.loading = true
                let res = await this.$store.dispatch('sopGroupAction', {})
                this.list = res.data
                if(this.list.length > 0 && !val){
                    this.parent.group_id = this.list[0].group_id
                }
                this.loading = false
            },
            async groupSort(arr){
                await this.$store.dispatch('sopGroupSortAction', {sort_group_ids:arr})
                .then(res=>{
                    this.$message.success('操作成功~')
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            toDel(item){
                let that = this
                this.$confirm({
                    title: `您确定删除该分组？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('sopDeleteGroupAction', {group_id:item.group_id})
                        .then(res=>{
                            that.$message.success('操作成功~')
                            that.getList()
                            resolve(res)
                        })
                        .catch(err=>{
                            that.$message.error('操作失败~')
                            reject(err)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            },
            hadleOver(index){
                this.hoverIndex = index
            },
            handleOut(){
                this.hoverIndex = -1
            },
            toCheck(item,index){
                this.checkIndex = index
                this.parent.group_id = item.group_id
            },
            onEnd(e){
                if(e.oldIndex == this.checkIndex){
                    this.checkIndex = e.newIndex
                }else if(e.newIndex <= this.checkIndex){
                    this.checkIndex++
                }else if(e.oldIndex < this.checkIndex){
                    this.checkIndex--
                }
                let arr = []
                this.list.forEach(item=>{
                    arr.push(item.group_id)
                })
                this.groupSort(arr)
            },
            handleInfiniteOnLoad(){},
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
        },
    }
</script>

<style lang="scss" scoped>
    .talkingSkills-left{
        &-nav{
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: 1px solid #eee;
        }
        &-list{
            overflow-y: scroll;
            &-bg{
                background: #e6fff6;
            }
            &-bgF{
                background: #e6fff6;
            }
        }
        &-item{
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            padding: 12px 0 12px 10px;
            cursor: pointer;
            &-icon{
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
            &-count{
                position: absolute;
                right: 25px;
                font-size: 12px;
                color: #999;
            }
        }
    }
</style>