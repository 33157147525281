<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场应用</a-breadcrumb-item>
            <a-breadcrumb-item>SOP</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="talkingSkills" >
                <div class="talkingSkills-left">
                    <grouping ref="grouping" />
                </div>
                <div :style="{ width:wWidth,paddingLeft:'25px'}">
                    <list :groupID="group_id" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import list from './list'
    import grouping from './grouping'
    export default {
        name:"talkingSkills",
        data() {
            return {
                myWidth:0,
                group_id:0,
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            list,
            grouping,
        },
        computed: {
            wWidth(){
                return `${Number(this.myWidth) - 480}px`
            }
        },
        created () {
            this.myWidth = this.windowHeight()
        },
        methods: {
            refreshList(){
                this.$refs.grouping.getList(1)
            },
            windowHeight() {
                return document.body.offsetWidth
            },
        },
    }
</script>

<style lang="scss" scoped>
    .talkingSkills{
        display: flex;
        .talkingSkills-left{
            width: 250px;
            border-right: 1px solid #eee;
        }
    }
</style>